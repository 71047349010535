
import { defineComponent, ref } from 'vue'
import {
  useMessage,
  NButton,
  NModal,
  NForm,
  NFormItem,
  NInputNumber,
  NInput,
  NIcon,
  NSwitch,
} from 'naive-ui'
import { API } from '@/api/api'
import { CreateOutline } from '@vicons/ionicons5'

export default defineComponent({
  components: {
    NButton,
    NModal,
    NForm,
    NFormItem,
    NInputNumber,
    NInput,
    NIcon,
    CreateOutline,
    NSwitch,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    emitUpdate: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const message = useMessage()
    const showModal = ref(false)
    const model = ref<any>()

    async function updateData() {
      try {
        const response = await API().put(
          `/dashboard/sites/${props.data.id}`,
          model.value
        )

        if (response.status === 200) {
          message.success('Site atualizado com sucesso!')
          props.emitUpdate()
          showModal.value = false
        } else {
          message.error(
            'Erro ao atualizar site, verifique se os dados estão corretos e tente novamente mais tarde'
          )
        }
      } catch (error) {
        console.error(error)
        message.error(
          'Erro interno - Tente novamente mais tarde e contate o suporte.'
        )
      }
    }

    function openModal() {
      model.value = {
        ...props.data,
      }
      model.value.status = props.data.status ? true : false
      showModal.value = true
    }

    return {
      model,
      showModal,
      updateData,
      openModal,
    }
  },
})
