
import { defineComponent, ref, onMounted, h } from 'vue'
import { NDataTable, NTag, NInput, NButton } from 'naive-ui'
import { useSites } from '@/store'
import { TableColumns } from 'naive-ui/lib/data-table/src/interface'
import ModalEditarSite from './modals/ModalEditarSite.vue'
import ModalAdicionarSite from './modals/ModalAdicionarSite.vue'
import { SearchOutline } from '@vicons/ionicons5'
import ModalHistoricSiteVue from './modals/ModalHistoricSite.vue'

export default defineComponent({
  components: {
    NDataTable,
    SearchOutline,
    ModalAdicionarSite,
    NInput,
    NButton,
  },
  setup() {
    const sites = useSites()
    const formRef = ref<{ search: string | null }>({
      search: null,
    })

    async function search() {
      sites.params = ``
      if (formRef.value.search != null && formRef.value.search.length > 0) {
        sites.params = `&search=${formRef.value.search}`
      }
      sites.resetPagination()
      await sites.fetch()
    }

    function getColumns() {
      return ref<TableColumns<any>>([
        {
          title: '#',
          key: 'id',
        },
        {
          title: 'Nome',
          key: 'nome',
        },
        {
          title: 'URL',
          key: 'url',
          render(row) {
            return h(
              NTag,
              {
                style: {
                  marginRight: '6px',
                },
                type: 'info',
                onclick: () => {
                  window.open(`https://${row.url}`, '_blank')
                },
              },
              {
                default: () => {
                  return `https://${row.url}`
                },
              }
            )
          },
        },
        {
          title: 'Status',
          key: 'status',
          render(row) {
            return h(
              NTag,
              {
                style: {
                  marginRight: '6px',
                },
                type: row.status == 1 ? 'success' : 'error',
              },
              {
                default: () => {
                  if (row.status == 1) {
                    return 'Ativo'
                  } else {
                    return 'Inativo'
                  }
                },
              }
            )
          },
        },
        {
          title: 'Ações',
          key: 'acoes',
          align: 'right',
          render: (row) => {
            return h('div', { class: 'space-x-2 justify-end' }, [
              h(ModalEditarSite, {
                data: row,
                emitUpdate: () => {
                  sites.fetch()
                },
              }),
              h(ModalHistoricSiteVue, {
                data: row,
              }),
            ])
          },
        },
      ])
    }

    onMounted(async () => {
      await sites.fetch()
    })

    return {
      sites,
      columns: getColumns(),
      search,
      formRef,
    }
  },
})
