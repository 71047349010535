
import { defineComponent, ref } from 'vue'
import {
  useMessage,
  NButton,
  NModal,
  NForm,
  NFormItem,
  NInputNumber,
  NInput,
  NIcon,
  NSpin,
} from 'naive-ui'
import { API } from '@/api/api'
import { AddOutline } from '@vicons/ionicons5'

export default defineComponent({
  components: {
    NButton,
    NModal,
    NForm,
    NFormItem,
    NInput,
    NInputNumber,
    NIcon,
    NSpin,
    AddOutline,
  },
  props: {
    emitUpdate: {
      type: Function,
      required: true,
    },
  },
  emits: ['emitUpdate'],
  setup(props) {
    const message = useMessage()
    const showModal = ref(false)
    const model = ref({
      nome: null,
      url: null,
      ftp_host: null,
      ftp_user: null,
      ftp_pass: null,
      ftp_port: null,
    })

    const isLoading = ref<boolean>(false)

    async function createData() {
      try {
        isLoading.value = true
        const data = {
          ...model.value,
        }

        const response = await API().post(`/dashboard/sites`, data)

        if (response.status === 200) {
          message.success('Site cadastrado com sucesso!')
          props.emitUpdate()
          model.value = {
            nome: null,
            url: null,
            ftp_host: null,
            ftp_user: null,
            ftp_pass: null,
            ftp_port: null,
          }
          showModal.value = false
        } else {
          message.error(
            'Erro ao cadastrar site, verifique se os dados estão corretos e tente novamente mais tarde'
          )
        }
      } catch (error) {
        console.error(error)
        message.error(
          'Erro interno - Tente novamente mais tarde e contate o suporte.'
        )
      } finally {
        isLoading.value = false
      }
    }

    async function openModal() {
      showModal.value = true
    }

    return {
      model,
      showModal,
      createData,
      openModal,
      isLoading,
    }
  },
})
