
import { defineComponent, ref, h } from 'vue'
import {
  useMessage,
  NButton,
  NModal,
  NIcon,
  NSpin,
  DataTableColumns,
  NDataTable,
} from 'naive-ui'
import { API } from '@/api/api'
import { AlbumsOutline } from '@vicons/ionicons5'
import ModalHistoricDetailVue from '@/views/home/modals/ModalHistoricDetail.vue'

const createColumns = (): DataTableColumns<any> => {
  return [
    {
      title: 'Ação',
      key: 'descricao',
    },
    {
      title: 'Usuario',
      key: 'usuario.nome',
    },
    {
      title: 'Data',
      key: 'date',
      render: (row) => {
        return new Date(row.created_at).toLocaleString()
      },
    },
    {
      title: '',
      key: 'actions',
      render(row) {
        return h(ModalHistoricDetailVue, {
          file: row,
        })
      },
    },
  ]
}

export default defineComponent({
  components: {
    NButton,
    NModal,
    NIcon,
    AlbumsOutline,
    NSpin,
    NDataTable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showModal = ref(false)
    const historics = ref<any>()
    const isLoading = ref(false)

    async function fetchHistoric() {
      try {
        isLoading.value = true
        const response = await API().get(
          `/dashboard/sites/${props.data.id}/historico`
        )
        if (response.status == 200) {
          historics.value = response.data
        } else {
          useMessage().error('Erro ao carregar histórico')
        }
      } catch (error) {
        console.error(error)
        useMessage().error(
          'Erro interno - Tente novamente mais tarde e contate o suporte.'
        )
      } finally {
        isLoading.value = false
      }
    }

    function openModal() {
      showModal.value = true
      fetchHistoric()
    }

    return {
      openModal,
      showModal,
      isLoading,
      historics,
      columns: createColumns(),
      pagination: false as const,
    }
  },
})
